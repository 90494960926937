@font-face {
font-family: '__AktivGroteskGeberit_11b94d';
src: url(/_next/static/media/0996c29f91129351-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__AktivGroteskGeberit_11b94d';
src: url(/_next/static/media/f53238b9f95a76dc-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: italic;
}

@font-face {
font-family: '__AktivGroteskGeberit_11b94d';
src: url(/_next/static/media/1d907add1022b6e8-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__AktivGroteskGeberit_11b94d';
src: url(/_next/static/media/6434e179fba6af61-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__AktivGroteskGeberit_11b94d';
src: url(/_next/static/media/8af0cead9dcdaaf5-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__AktivGroteskGeberit_11b94d';
src: url(/_next/static/media/1da43a518a9902b9-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: '__AktivGroteskGeberit_11b94d';
src: url(/_next/static/media/3cea44b56d9631cb-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__AktivGroteskGeberit_11b94d';
src: url(/_next/static/media/c0d592b4b1184ba5-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: italic;
}@font-face {font-family: '__AktivGroteskGeberit_Fallback_11b94d';src: local("Arial");ascent-override: 101.03%;descent-override: 24.04%;line-gap-override: 0.00%;size-adjust: 102.34%
}.__className_11b94d {font-family: '__AktivGroteskGeberit_11b94d', '__AktivGroteskGeberit_Fallback_11b94d', Arial, Sans-serif
}

